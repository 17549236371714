.main {
  margin: 0;
  padding: 0;
  width: 30%;
}

.title {
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  color: var(--c-black);
  padding-bottom: 1rem;
}

.confirm {
  background: url('../../../images/illus/signUp.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 3rem;
  border-radius: 1rem;
}

.confirmInner {
  background: var(--c-primary-light);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .actionIcons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
  }

  .button {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--c-white);
    background: var(--c-red);
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }

    & > span {
      font-size: 0.8rem;
      margin-right: 0.25rem;
      padding: 0.1rem;
      color: var(--c-red);
      background: var(--c-white);
      border-radius: 50%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(2) {
      background: var(--c-green);

      & > span {
        color: var(--c-green);
        margin-right: 0;
        margin-left: 0.25rem;
      }
    }
  }
}

@media (max-width: 1279px) {
  .main {
    width: 100%;
  }

  .confirm {
    padding: 1rem;
  }

  .confirmInner {
    .actionIcons {
      width: 80%;
    }
  }
}
