.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-width: 15rem;
  display: flex;
}

.speech {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mic {
  background: var(--c-primary);
  color: var(--c-white);
  padding-left: 0rem;
  font-size: 1rem;
  margin-left: 0.5rem;
  padding: 0.25rem;
  border-radius: 50%;
  z-index: 2;
}

.circles {
  height: 3rem;
  position: absolute;
  width: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding-left: 0.25rem;

  & > div {
    animation: growAndFade 3s infinite ease-out;
    background-color: var(--c-primary);
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .circle1 {
    animation-delay: 1s;
  }
  .circle2 {
    animation-delay: 2s;
  }
  .circle3 {
    animation-delay: 3s;
  }
}

@keyframes growAndFade {
  0% {
    opacity: 0.5;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
