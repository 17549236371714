.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.settings {
  padding: 0 1rem;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
  padding-bottom: 4rem;
}

.loggedUser {
  font-family: var(--ff-title);
  font-weight: 700;
  color: var(--c-font-dark);
  font-size: 2rem;
  padding-top: 8rem;
}

.logOutButton {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background: var(--c-red);
  padding: 2rem;
  color: var(--c-white);
  cursor: pointer;
  box-shadow: 0rem 0.3rem 0.5rem var(--c-shadow);
  font-size: 1.5rem;
  font-weight: 800;
  font-family: var(--c-primary);
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    padding-top: 0.5rem;

    &:first-child {
      font-size: 3rem;
    }
  }
}

.setting {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.05rem solid var(--c-red);
  padding: 0.5rem 0;
  margin: 1rem 0;

  .settingName {
    font-family: var(--ff-title);
    font-weight: 600;
    font-size: 1.1rem;
    color: var(--c-font);
  }

  & > span {
    font-size: 1.7rem;
    cursor: pointer;
    color: var(--c-red);
  }
}

@media (max-width: 1279px) {
  .settings {
    padding: 0 0.5rem;
  }

  .content {
    justify-content: center;
  }

  .loggedUser {
    padding-top: 0rem;
  }
}
