.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.myListings {
  padding: 0 1rem;
  height: 100%;
}

.title {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--c-black);
}

.headerBottom {
  // padding: 1rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    // width: 45%;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0rem;
    }
  }

  .headerInner {
    display: flex;
    align-items: center;

    & > div:first-child {
      margin-right: 1rem;
    }
  }

  .addListing {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 1rem;
    background: var(--c-primary);
    color: var(--c-white);
    font-size: 0.8rem;
    font-weight: 600;
    white-space: nowrap;
    border: 0.1rem solid var(--c-primary);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease;

    & > span {
      padding-right: 0.5rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  padding-bottom: 4rem;

  & > div {
    padding: 0.5rem 1rem;
  }
}

.listings {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  .searchResults {
    text-align: center;
    font-size: 1.2rem;
    color: var(--c-black);
    font-weight: 700;
    padding: 1rem 0;
  }
}

@media (max-width: 1279px) {
  .myListings {
    padding: 0 0.5rem;
  }

  .headerBottom {
    flex-direction: column;
    align-items: flex-end;

    & > div {
      margin-right: 0;
    }

    .headerInner {
      padding-top: 0.5rem;

      & > div:first-child {
        margin-right: 0.5rem;
      }
    }

    .addListing {
      padding: 0.35rem 0.5rem;
      font-size: 0.6rem;

      & > span {
        padding-right: 0.25rem;
        font-size: 1rem;
      }
    }
  }

  .content {
    & > div {
      padding: 0.5rem 0;
    }
  }
}
