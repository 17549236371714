.main {
  position: relative;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* style2 ripple*/

  .ldsRipple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .ldsRipple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .ldsRipple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  .message {
    font-family: var(--ff-primary);
    color: var(--c-font);
    font-size: 1.2rem;
    padding: 0.4rem 0;
    font-weight: 600;
  }
}

/* style2 ripple*/

@keyframes ldsRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
