.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.businesses {
  padding: 0 1rem;
  height: 100%;
}

.headerInner {
  display: flex;

  & > a:first-child,
  & > div:first-child {
    margin-right: 1rem;
  }
}

.addBusiness {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;

  & > span {
    padding-right: 0.5rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  padding-bottom: 4rem;
}

@media (max-width: 1279px) {
  .businesses {
    padding: 0 0.5rem;
  }

  .businessesData {
    width: fit-content;
  }

  .headerInner {
    margin-left: auto;
    width: fit-content;

    // & > a:first-child {
    //   margin-right: 0.5rem;
    // }
  }

  .addBusiness {
    // width: fit-content;
    padding: 0.25rem 0.5rem;
    font-size: 0.6rem;
    margin-left: auto;

    & > span {
      font-size: 0.8rem;
      padding-right: 0.25rem;
    }
  }

  .content {
    padding-bottom: 1rem;
  }
}
