.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.editCategory {
  height: 100%;
  width: 100%;
}

.title {
  font-size: 1.1rem;
  font-weight: 700;
  padding: 1rem 0;
  color: var(--c-black);
}

.imageUpload {
  display: inline-block;
}

.addSubCategories {
  font-size: 1.1rem;
  color: var(--c-black);
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  & > span {
    transition: all 0.5s ease;
    cursor: pointer;
    font-size: 1.5rem;

    &:first-child {
      font-size: 1.5rem;
      color: var(--c-primary);
      margin-left: 0rem;
      margin-right: 0.5rem;
    }

    &:nth-child(2) {
      font-size: 2rem;
      color: var(--c-primary);
      margin: 0 0.5rem;
    }
  }
}

.subCategory {
  height: 100%;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      &:nth-child(2) {
        display: flex;
        align-items: center;
        color: var(--c-red);
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: 600;

        & > span {
          font-size: 1rem;
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.label {
  font-size: 1rem;
  font-weight: 700;
  color: var(--c-black);
}

.addMore {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--c-primary);
    color: var(--c-white);
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 0.1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;

    & > span {
      margin-left: 0.5rem;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem !important;
  justify-content: flex-end;
  width: 100%;
  border-top: 0.1rem solid var(--c-primary);
  margin-top: 1rem;

  & > div {
    color: var(--c-white);
    border: 0.1rem solid var(--c-primary);
    background: var(--c-primary);
    // margin-right: 1rem;
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    white-space: nowrap;

    & > span {
      margin-right: 0.25rem;
      font-size: 1rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .discard {
    color: var(--c-primary);
    border: 0.1rem solid var(--c-primary);
    background: var(--c-white);
    margin-right: 1rem;
  }
}

.content {
  width: 100%;
  padding-bottom: 4rem;
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}
