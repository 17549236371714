@import 'src/styles/mixins';

.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.viewLeads {
  padding: 0 1rem;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  padding-bottom: 4rem;
}

.downloadLeads {
  @include buttonStyle;

  align-self: flex-end;
  margin-right: 1rem;

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    padding-left: 0.5rem;
  }
}

.leadsData {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1279px) {
  .leadsData {
    width: fit-content;
  }

  .viewLeads {
    padding: 0 0.5rem;
  }

  .downloadLeads {
    width: fit-content;
    padding: 0.25rem 0.5rem;
    margin-left: auto;
    margin-right: 0;
    font-size: 0.6rem;

    & > span {
      padding-left: 0.25rem;
      font-size: 0.8rem;
    }
  }
}
