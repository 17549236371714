.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.loginOuter {
  height: 100vh;
  width: 100%;
  background: url('../../images/illus/login.png');
  background-repeat: no-repeat;
}

.login {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--c-primary-light);
  border-radius: 1rem;
  padding: 4rem 4rem 6rem;
  width: 40vw;

  & > div {
    padding: 1rem 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  .logo {
    width: 10rem;
    height: 10rem;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .button {
    width: 100%;

    & > button {
      cursor: pointer;
      width: 100%;
      outline: none;
      border: none;
      background: var(--c-primary);
      border-radius: 0.5rem;
      color: var(--c-white);
      font-size: 1rem;
      font-weight: 700;
      padding: 0.75rem 0.5rem;
      text-align: center;
    }
  }

  .forgotPin {
    width: 100%;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > a {
      color: var(--c-black);
      font-size: 1rem;
      font-weight: 600;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 1279px) {
  .login {
    padding: 0 1rem;
  }

  .loginForm {
    width: 100%;
    padding: 2rem 1rem 4rem;

    .button > button {
      padding: 0.5rem 2rem;
      font-size: 0.8rem;
    }

    .forgotPin > a {
      font-size: 0.8rem;
    }
  }
}
