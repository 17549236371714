.addBusiness {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  justify-content: center;
  padding: 0.25rem 1rem !important  ;
  margin-top: 1rem;
  margin-right: 0.5rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;

  & > span {
    font-size: 1rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.part3 {
  .tags {
    padding: 1rem;

    .tagHeading {
      font-weight: 700;
      font-size: 1rem;
      color: var(--c-black);
      padding-bottom: 1rem;
    }

    .addedTags {
      display: flex;
      flex-wrap: wrap;
    }

    .tag {
      border-radius: 0.5rem;
      padding: 0.3rem 0.8rem;
      padding-right: 0.5rem;
      background: var(--c-primary-light);
      color: var(--c-primary);
      border: 0.05rem solid var(--c-primary);
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 600;
      margin: 0.5rem;

      & > span {
        font-size: 0.9rem;
        padding-left: 0.3rem;
        color: var(--c-primary);
        cursor: pointer;
      }
    }
  }

  & > div {
    padding: 1rem;
  }

  .socialPlatform {
    .label {
      font-size: 1rem;
      color: var(--c-black);
      font-weight: 700;
    }
    .socialPlatformInner {
      display: flex;
      align-items: center;

      .image {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      & > span {
        margin-left: 0.5rem;
      }
    }
  }

  .dateOfEstablishment {
    width: 50%;
  }

  .workingHours {
    width: 100%;
    margin-top: 1rem;

    .title {
      color: var(--c-black);
      font-size: 1rem;
      font-weight: 700;
    }

    .topLabels {
      display: flex;
      align-items: center;

      .label {
        width: 30%;
      }

      .isClosed {
        width: 10%;
      }

      .labelsRight {
        width: 60%;
        display: flex;
        justify-content: space-between;
        margin-left: 1rem;

        .label {
          width: 48%;
        }
      }
    }

    .middleLabels {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;

      .isOpen24Hours {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: var(--c-black);
        font-weight: 600;

        & > span {
          margin-left: 0.5rem;
          cursor: pointer;
          font-size: 2.5rem;
        }
      }

      .copyToAll {
        font-size: 1rem;
        color: var(--c-black);
        font-weight: 600;

        & > input[type='radio' i] {
          margin: 0;
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }
    }

    .label {
      font-size: 1rem;
      color: var(--c-black);
      font-weight: 700;
      width: 30%;
    }

    .isClosed {
      display: flex;
      align-items: center;
      width: 10%;

      & > span {
        font-size: 2.5rem;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }

    .day {
      margin: 1rem 0;
      display: flex;
      align-items: center;

      .dayLabel {
        text-transform: uppercase;
      }

      .open24Hours {
        color: var(--c-primary);
        font-size: 1rem;
        font-weight: 600;
      }

      .row {
        width: 60%;
        margin-left: 1rem;
      }
    }
  }
}

@media (max-width: 1279px) {
  .addBusiness {
    width: fit-content;
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    margin-left: auto;

    & > span {
      font-size: 0.8rem;
      padding-right: 0.25rem;
    }
  }

  .part3 {
    .socialPlatform {
      .image {
        width: 2.25rem;
        height: 2.25rem;
      }

      &:last-child {
        margin-top: 0rem;
        padding-top: 1rem;
      }
    }

    .dateOfEstablishment {
      width: 100%;
    }

    .workingHours {
      .dayLabel {
        min-width: 6rem;
      }

      .topLabels {
        .isClosed {
          width: 15%;
        }

        .labelsRight {
          width: 55%;
        }
      }

      .middleLabels {
        flex-direction: column;

        .isOpen24Hours {
          margin-bottom: 0.5rem;

          & > span {
            font-size: 2.5rem;
          }
        }
      }

      .isClosed {
        width: 15%;

        & > span {
          font-size: 2.5rem;
        }
      }

      .day {
        .row {
          display: flex;
          flex-direction: row;
          width: 55%;

          & > :first-child {
            margin-right: 1rem;
          }
        }
      }

      .dayAlt {
        justify-content: space-between;
      }
    }
  }

  .part3 {
    & > div {
      padding: 0.5rem;
    }
  }
}
