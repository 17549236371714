.main {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1280px;
  position: relative;
}

.close {
  position: absolute;
  color: var(--c-primary);
  background: var(--c-white);
  border-radius: 50%;
  padding: 0.25rem;
  right: 2.2rem;
  top: 2.2rem;
  cursor: pointer;
  z-index: 1;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.viewListing {
  background: url('../../../images/illus/signUp.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  width: 100%;
  padding: 3rem;
  border-radius: 1rem;
}

.viewListingInner {
  background: var(--c-white);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 100%;

  .listingImages {
    width: 100%;
    height: 100%;
  }

  .slide {
    width: 100%;
    aspect-ratio: 16/5;
    background: var(--c-primary-light);
    border-radius: 0.5rem;
    border: 0.05rem solid var(--c-primary);
    cursor: grab;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .title {
    width: 100%;
    color: var(--c-black);
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    padding: 1rem;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contactNo,
    .address {
      & > div {
        cursor: pointer;

        &:first-child {
          font-size: 1rem;
          font-weight: 600;
          color: var(--c-black);
        }

        &:last-child {
          display: flex;
          align-items: center;
          color: var(--c-black);
          font-size: 0.8rem;

          & > span {
            color: var(--c-primary);
            padding-right: 0.25rem;
          }
        }
      }
    }

    .contactNo > div:nth-child(2) {
      display: flex;
      align-items: center;
      color: var(--c-white);
      padding: 0.25rem 0.5rem;
      background: var(--c-primary);
      border-radius: 0.2rem;
      transition: all 0.5s ease;

      & > span {
        color: var(--c-white);
        padding-right: 0.25rem;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .adInfo {
    font-family: var(--ff-content);
    font-size: 0.8rem;
    font-weight: 500;
    text-align: justify;
    color: var(--c-font);

    & > div:first-child {
      font-size: 1rem;
      font-weight: 600;
      color: var(--c-black);
      text-align: left;
      padding: 1rem 0;
    }
  }
}

@media (max-width: 1279px) {
  .viewListing {
    padding: 1rem;
    height: 80vh;
  }

  .close {
    right: 0.3rem;
    top: 0.3rem;
  }

  .viewListingInner {
    padding: 1rem;

    .slide {
      aspect-ratio: 1;
    }

    .info {
      flex-direction: column;
      align-self: flex-start;

      .contactNo,
      .address {
        padding: 1rem 0;
        align-self: flex-start;

        & > div:nth-child(1) {
          padding-bottom: 0.5rem;
          font-size: 1rem;
        }
      }

      .contactNo > a {
        font-size: 0.8rem;

        & > span {
          font-size: 1rem;
        }
      }

      .address > div > span {
        font-size: 1rem;
      }
    }
    .adInfo > div:first-child {
      padding: 0.5rem 0;
    }
  }
}
