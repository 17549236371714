@import 'src/styles/mixins';

.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

.settings {
  padding: 0 1rem;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
  padding: 1rem;
  padding-bottom: 4rem;
}

.actionButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .download {
    @include buttonStyle;

    &:hover {
      transform: scale(1.05);
    }

    & > span {
      padding-left: 0.5rem;
    }
  }
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardFront {
  width: 25rem;
  aspect-ratio: 1.75;
  background: var(--c-primary-light);
  background-size: contain;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 2rem;

  .image {
    width: 5rem;
    display: flex;
    align-items: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .middleData {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    color: var(--c-white);
    font-size: 1.2rem;
    font-weight: 600;

    .qrCode {
      aspect-ratio: 1;
      margin-right: 1rem;
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      text-transform: capitalize;

      .validity {
        font-size: 1rem;

        & > span {
          font-weight: 500;
        }
      }
    }
  }

  .lastData {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;

    & > div {
      color: var(--c-white);
      font-size: 1rem;
      font-weight: 600;

      & > span {
        font-weight: 500;
      }
    }
  }
}

.cardFrontIsDownloading {
  width: 525px !important;

  .image {
    width: 105px !important;
  }

  .middleData {
    font-size: 25.2px !important;

    .userInfo {
      .validity {
        font-size: 21px !important;
      }
    }
  }

  .lastData {
    & > div {
      font-size: 21px;
    }
  }
}

.cardBack {
  width: 25rem;
  aspect-ratio: 1.75;
  background: var(--c-primary-light);
  background-size: contain;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 3rem;

  .top {
    height: 90%;
    display: flex;
    align-items: center;
  }

  .image {
    width: 12rem;
    display: flex;
    align-items: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .link {
    height: 10%;
    color: var(--c-white);
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.cardBackIsDownloading {
  width: 525px !important;

  .image {
    width: 252px !important;
  }

  .link {
    font-size: 25.2px !important;
  }
}

@media (max-width: 1279px) {
  .settings {
    padding: 0 0.5rem;
  }

  .content {
    padding: 0.5rem;
  }

  .cards {
    align-items: flex-start;

    .cardBack,
    .cardFront {
      width: 22rem;
    }
  }
}
