.main {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 20rem;
  height: 100%;

  & > svg {
    width: 100%;
    height: 100%;
    // object-fit: cover;
    object-fit: contain;
  }
}

@media (max-width: 1279px) {
  .image {
    width: 20rem;
  }
}
