.main {
  width: 100%;
  height: 100%;
  // padding: 2rem 0;
  overflow: hidden;
  position: relative;

  input {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 11.4rem;
    height: 1.5rem;
    padding: 0 0.57rem;
    border-radius: 0.15rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 0.67rem;
    outline: none;
    text-overflow: ellipsis;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .locationPicker {
    position: absolute;
    background: var(--c-white);
    border: 0;
    margin: 0.5rem;
    padding: 0;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 0.1rem;
    height: 1.9rem;
    width: 1.9rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    overflow: hidden;
    top: 60%;
    right: 0;

    & > span {
      color: var(--c-red);
    }
  }
}

@media (max-width: 1279px) {
  .main {
    input {
      bottom: 0;
    }

    .locationPicker {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 0.6rem;
      top: 45%;
      z-index: 1;
    }
  }
}
