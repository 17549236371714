.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.features {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;
}

.categories {
  width: 100%;
  padding: 1rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.title {
  font-size: 1.1rem;
  font-weight: 700;
  padding: 1rem 0;
  color: var(--c-black);
}

.serviceField {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  border: 0.1rem solid var(--c-primary);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;
  color: var(--c-black);

  &:hover {
    transform: scale(1.05);
    background: var(--c-primary);
    color: var(--c-white);
  }

  & > div {
    &:first-child {
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
      overflow: hidden;
      border: 0.1rem solid var(--c-white);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:last-child {
      width: 70%;
      font-size: 0.8rem;
      padding: 0 0.5rem;
      font-weight: 600;
    }
  }
}

.active {
  background: var(--c-primary);
  color: var(--c-white);
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1279px) {
  .title {
    padding: 0.5rem 0;
  }

  .categories {
    padding: 1rem 0;
    grid-template-columns: 1fr 1fr;
  }
}
