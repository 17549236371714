.title {
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 0;
  color: var(--c-black);
}

.addBusiness {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  justify-content: center;
  padding: 0.25rem 1rem !important  ;
  margin-top: 1rem;
  margin-right: 0.5rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;

  & > span {
    font-size: 1rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.part4 {
  & > div {
    padding: 1rem;
  }

  .locationFields > div {
    width: 30%;
  }

  .postProduct {
    font-size: 1rem;
    color: var(--c-black);
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 1rem;

    & > span {
      display: flex;
      align-items: center;

      & > span {
        transition: all 0.5s ease;
        cursor: pointer;
        font-size: 1.25rem;
        color: var(--c-primary);
        margin-right: 0.5rem;
      }

      &:nth-child(2) {
        margin-left: 0.5rem;

        & > span {
          margin: 0 0.5rem;
          font-size: 3rem;
        }
      }
    }
  }

  .location {
    display: flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 16/9;

    .title {
      font-weight: 700;
    }
  }
}

@media (max-width: 1279px) {
  .addBusiness {
    width: fit-content;
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    margin-left: auto;

    & > span {
      font-size: 0.8rem;
      padding-right: 0.25rem;
    }
  }

  .title {
    padding: 0.5rem 0;
  }

  .part4 {
    & > div {
      padding: 0.5rem;
    }

    .locationFields > div {
      width: 100%;

      &:nth-child(2),
      &:nth-child(3) {
        padding-top: 1rem;
      }
    }

    .postProduct {
      & > span {
        & > span {
          font-size: 1.5rem;
        }

        &:nth-child(2) {
          & > span {
            font-size: 3rem;
          }
        }
      }
    }

    .location {
      aspect-ratio: 3/4;
    }
  }
}
