.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.demographics {
  padding: 0 1rem;
  height: 100%;
}

.headerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
}

.addFaq,
.addTestimonies {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-left: 1rem;

  & > span {
    padding-right: 0.5rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;

  & > div {
    margin-bottom: 1rem;
  }
}

.imageUpload {
  width: fit-content;

  & > div > div {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.banners {
  padding: 1rem;
  padding-left: 0;
}

.links {
  padding: 1rem 0;

  .linksHeading {
    font-weight: 700;
    font-size: 1rem;
    color: var(--c-black);
    padding-bottom: 1rem;
  }

  .addedLinks {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem 0;
    padding-right: 1rem;
  }

  .link {
    border-radius: 0.5rem;
    // padding: 0.3rem 0.8rem;
    // padding-right: 0.5rem;
    background: var(--c-white);
    color: var(--c-primary);
    border: 0.05rem solid var(--c-primary);
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0.25rem;
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    & > span {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      padding: 0.25rem;
      border-radius: 50%;
      aspect-ratio: 1;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--c-primary);
      color: var(--c-white);
      cursor: pointer;
      transition: all 0.5s ease;

      &:hover {
        transform: translate(50%, -50%) scale(1.05);
      }
    }
  }
}

.label {
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem 0;
  color: var(--c-black);
}

.websiteContact,
.websiteSocials,
.testimonies {
  & > div {
    padding: 0.5rem 0;
  }

  .label {
    font-size: 1.1rem;
    padding-bottom: 0;
  }
}

.testimony {
  .title {
    font-size: 1rem;
    font-weight: 700;
    color: var(--c-black);
  }

  & > div {
    padding: 0.5rem 0;
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem !important;
  justify-content: space-between;
  width: 100%;
  border-top: 0.1rem solid var(--c-primary);
  margin-top: 1rem;

  & > div {
    color: var(--c-white);
    border: 0.1rem solid var(--c-primary);
    background: var(--c-primary);
    // margin-right: 1rem;
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    white-space: nowrap;

    & > span {
      margin-right: 0.25rem;
      font-size: 1rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .discard {
    color: var(--c-primary);
    border: 0.1rem solid var(--c-primary);
    background: var(--c-white);
    margin-right: 1rem;
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1279px) {
  .links {
    .addedLinks {
      padding: 0.5rem 0;
      padding-right: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}
