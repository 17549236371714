.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .label {
    outline: none;
    border: none;
    font-size: 1rem;
    color: var(--c-black);
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    font-family: var(--ff-title);
    font-weight: 700;
  }

  .textField {
    display: flex;
    border: 0.1rem solid var(--c-primary);
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    background: var(--c-white);

    .textarea {
      outline: none;
      border: none;
      font-family: var(--ff-content);
      color: var(--c-font);
      background: transparent;
      font-style: italic;
      font-weight: 400;
      width: 100%;
      font-size: 0.8rem;

      &::placeholder {
        font-family: var(--ff-content);
        color: var(--c-font);
      }
    }

    .iconLeft {
      color: var(--c-primary);
      font-weight: 400;
      padding-right: 0.5rem;
    }
  }
}

@media (max-width: 1279px) {
  .textField {
    .iconLeft {
      font-size: 1rem;
    }
  }
}
