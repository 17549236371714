.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.changePinOuter {
  width: 100%;
  background: var(--c-white);
  background-repeat: no-repeat;
  padding: 0 1rem;
}

.changePin {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}

.changePinForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 0 2rem;
  width: 100%;

  & > div {
    padding: 1rem 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  .confirm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      padding-top: 1.5rem;
      padding-left: 0.5rem;
    }
  }
}

.button {
  width: 100%;

  & > button {
    cursor: pointer;
    width: 100%;
    outline: none;
    border: none;
    background: var(--c-primary);
    border-radius: 0.5rem;
    color: var(--c-white);
    font-size: 0.8rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 1279px) {
  .changePinOuter {
    padding: 0 0.5rem;
  }

  .changePin {
    margin-top: 1rem;
  }

  .changePinForm {
    padding: 0 0.5rem;
  }

  .button {
    width: fit-content;
    margin-left: auto;

    & > button {
      border-radius: 0.25rem;
      border: 0.1rem solid var(--c-primary);
      font-size: 0.6rem;
      padding: 0.25rem 1rem;
    }
  }
}
