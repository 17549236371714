.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.faqOuter {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;
}

.addFaq {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-left: 1rem;

  & > span {
    padding-right: 0.5rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.headerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;

  & > div {
    margin-bottom: 1rem;
  }
}

.title {
  font-size: 1.1rem;
  font-weight: 700;
  padding: 1rem 0;
  color: var(--c-black);
}

.faq {
  height: 100%;
  width: 100%;
  border-top: 0.1rem solid var(--c-border);
  padding-bottom: 1.5rem;

  .title {
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;

    & > span {
      &:nth-child(2) {
        display: flex;
        align-items: center;
        color: var(--c-red);
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: 600;

        & > span {
          font-size: 1rem;
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.addMore {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--c-primary);
    color: var(--c-white);
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 0.1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;

    & > span {
      margin-left: 0.5rem;
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}
