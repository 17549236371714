.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;

  & > div {
    margin-bottom: 1rem;
  }
}

.approvalrequests {
  padding: 0 1rem;
  height: 100%;
}

.tabs {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;

  .tab {
    background: var(--c-white);
    color: var(--c-primary);
    border: 0.1rem solid var(--c-primary);
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    transition: all 0.5s ease;

    &:hover {
      background: var(--c-primary);
      color: var(--c-white);
    }
  }

  .active {
    background: var(--c-primary);
    color: var(--c-white);
  }
}

.actionButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  & > div {
    display: flex;
    align-items: center;
    background: var(--c-green);
    color: var(--c-white);
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease;
    font-size: 0.8rem;
    font-weight: 600;

    &:hover {
      transform: scale(1.05);
    }

    &:nth-child(2) {
      background: var(--c-red);
    }

    & > span {
      font-size: 1rem;
      margin-right: 0.25rem;
    }
  }
}

.offerings,
.businesses,
.users {
  padding: 0 1rem;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
}

.offeringsApprovalCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 0.1rem solid var(--c-primary);
  border-radius: 1rem;
  box-shadow: 0px 4px 16px 0px var(--c-shadow);
  margin: 0.5rem 0;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0px 2px 8px 0px var(--c-primary);
  }

  .offeringName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & > div:nth-child(1) {
      font-size: 1rem;
      color: var(--c-black);
      font-weight: 700;
      padding-bottom: 0.25rem;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      & > span {
        font-size: 1rem;
        margin-right: 0.25rem;
        color: var(--c-primary);
      }
    }
    & > div:nth-child(2) {
      font-size: 0.8rem;
      font-weight: 600;
      padding-bottom: 0.25rem;
      color: var(--c-black);
      display: flex;
      align-items: center;

      & > span {
        margin-left: 0.5rem;
        color: var(--c-font);

        &:nth-child(1) {
          margin-right: 0.25rem;
          color: var(--c-primary);
        }
      }
    }
  }

  .images {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem 0;

    .image {
      width: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.05rem solid var(--c-primary);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .contactPerson,
  .category,
  .phoneNo,
  .subCategory {
    font-size: 0.8rem;
    color: var(--c-black);
    font-weight: 700;
    display: flex;
    align-items: center;

    & > span {
      color: var(--c-font);

      &:nth-child(1) {
        color: var(--c-primary);
        margin-right: 0.25rem;
        font-size: 1rem;
      }

      &:nth-child(2) {
        margin-left: 0.25rem;
      }
    }
  }

  .phoneNo {
    margin-top: 0.5rem;
    width: fit-content;

    & > span {
      color: var(--c-blue);
    }
  }

  .description {
    font-size: 0.8rem;
    color: var(--c-font);
    font-weight: 600;
    margin: 0.5rem 0;
    font-family: var(--ff-content);
    text-align: justify;
    display: flex;
    align-items: flex-start;

    & > span {
      margin-right: 0.25rem;
    }
  }
}

.businessApprovalCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 0.1rem solid var(--c-primary);
  border-radius: 1rem;
  box-shadow: 0px 4px 16px 0px var(--c-shadow);
  margin: 0.5rem 0;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0px 2px 8px 0px var(--c-primary);
  }

  .businessName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & > div:nth-child(1) {
      font-size: 1rem;
      color: var(--c-black);
      font-weight: 700;
      padding-bottom: 0.25rem;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      & > span {
        font-size: 1rem;
        margin-right: 0.25rem;
        color: var(--c-primary);
      }
    }

    & > div:nth-child(2) {
      font-size: 0.8rem;
      font-weight: 600;
      padding-bottom: 0.25rem;
      color: var(--c-black);
      display: flex;
      align-items: center;

      & > span {
        margin-left: 0.5rem;
        color: var(--c-font);

        &:nth-child(1) {
          margin-right: 0.25rem;
          color: var(--c-primary);
        }
      }
    }
  }

  .images {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem 0;

    .image {
      width: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.05rem solid var(--c-primary);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .website,
  .address,
  .category,
  .subCategory,
  .state,
  .phoneNo,
  .city {
    font-size: 0.8rem;
    color: var(--c-black);
    font-weight: 700;
    display: flex;
    align-items: center;

    & > span {
      color: var(--c-font);

      &:nth-child(1) {
        color: var(--c-primary);
        margin-right: 0.25rem;
        font-size: 1rem;
      }

      &:nth-child(2) {
        margin-left: 0.25rem;
      }
    }
  }

  .phoneNo {
    margin-bottom: 0.5rem;
    width: fit-content;

    & > span {
      color: var(--c-blue);
    }
  }

  .instagram,
  .facebook {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--c-black);
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }

    .image {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .tags {
    margin: 0.5rem 0;

    .tagHeading {
      font-weight: 700;
      font-size: 0.8rem;
      color: var(--c-black);
      padding-bottom: 0.5rem;
    }

    .addedTags {
      display: flex;
      flex-wrap: wrap;
    }

    .tag {
      border-radius: 0.5rem;
      padding: 0.3rem 0.8rem;
      background: var(--c-primary-light);
      color: var(--c-primary);
      border: 0.05rem solid var(--c-primary);
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 600;
      margin-right: 1rem;
    }
  }

  .description {
    font-size: 0.8rem;
    color: var(--c-font);
    font-weight: 600;
    margin: 0.5rem 0;
    font-family: var(--ff-content);
    text-align: justify;
    display: flex;
    align-items: flex-start;

    & > span {
      margin-right: 0.25rem;
    }
  }
}

.userApprovalCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 0.1rem solid var(--c-primary);
  border-radius: 1rem;
  box-shadow: 0px 4px 16px 0px var(--c-shadow);
  margin: 0.5rem 0;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0px 2px 8px 0px var(--c-primary);
  }

  .userName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & > div:nth-child(1) {
      font-size: 1rem;
      color: var(--c-black);
      font-weight: 700;
      padding-bottom: 0.25rem;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      & > span {
        font-size: 1rem;
        margin-right: 0.25rem;
        color: var(--c-primary);
      }
    }

    & > div:nth-child(2) {
      font-size: 0.8rem;
      font-weight: 600;
      padding-bottom: 0.25rem;
      color: var(--c-black);
      display: flex;

      & > span {
        margin-left: 0.5rem;
        color: var(--c-font);

        &:nth-child(1) {
          margin-right: 0.25rem;
          color: var(--c-primary);
        }
      }
    }
  }

  .images {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem 0;

    .image {
      width: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.05rem solid var(--c-primary);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .gender {
    margin-bottom: 0.5rem;
  }

  .gender,
  .address,
  .state,
  .city,
  .phoneNo,
  .email {
    font-size: 0.8rem;
    color: var(--c-black);
    font-weight: 700;
    display: flex;
    align-items: center;

    & > span {
      color: var(--c-font);

      &:nth-child(1) {
        color: var(--c-primary);
        margin-right: 0.25rem;
        font-size: 1rem;
      }

      &:nth-child(2) {
        margin-left: 0.25rem;
      }
    }
  }

  .email {
    cursor: pointer;

    & > span {
      color: var(--c-link);

      &:nth-child(2) {
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }

  .phoneNo {
    & > span {
      color: var(--c-blue);
    }
  }
}

.rejectionReason {
  margin-top: 1rem;
}

.expand {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: var(--c-black);
  cursor: pointer;
  display: flex;
  align-items: center;

  & > span {
    font-size: 1.75rem;
  }
}
