.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.notificationSection {
  padding: 0 1rem;
  height: 100%;
}

.headerBottom {
  // padding: 1rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    // width: 45%;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0rem;
    }
  }
}

.markAllAsRead,
.deleteAll {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--c-white);
    color: var(--c-green);
    padding: 0.2rem;
    font-size: 0.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.deleteAll > span {
  color: var(--c-red);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 100%;
  padding-bottom: 4rem;

  & > div {
    padding: 0.5rem 1rem;
  }
}

.notifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.notification {
  background: var(--c-white);
  border: 2px solid var(--c-primary-light);
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 4px 8px var(--c-primary-light);
  }

  .notificationTitle {
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    color: var(--c-black);

    & > span {
      color: var(--c-primary);
      margin-right: 0.5rem;
      padding: 0.25rem;
      font-size: 0.8rem;
      border-radius: 50%;
      background: var(--c-primary-light);
    }
  }

  .notificationActions {
    display: flex;
    align-items: center;

    .timeStamp {
      font-size: 0.75rem;
      color: var(--c-black);
      margin-right: 1rem;
    }

    & > span {
      padding: 0.25rem;
      font-size: 0.8rem;
      border-radius: 50%;
      background: var(--c-primary-light);
      color: var(--c-green);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        color: var(--c-red);
        margin-left: 0.5rem;
      }
    }
  }
}

.active {
  background: var(--c-primary-light);
  border: 2px solid var(--c-primary);

  .notificationTitle > span,
  .notificationActions > span {
    background: var(--c-white);
  }
}

@media (max-width: 1279px) {
  .notificationSection {
    padding: 0 0.5rem;
  }

  .headerBottom {
    justify-content: flex-end;

    & > div {
      margin-right: 0.5rem;
    }
  }

  .markAllAsRead,
  .deleteAll {
    font-size: 0.6rem;
    padding: 0.25rem 0.5rem;
  }

  .content {
    padding-bottom: 1rem;

    & > div {
      padding: 0.5rem 0;
      padding-bottom: 4rem;
    }
  }

  .notification {
    padding: 0.5rem;

    .notificationTitle {
      font-size: 0.75rem;

      & > span {
        font-size: 1rem;
      }
    }

    .notificationActions {
      .timeStamp {
        font-size: 0.5rem;
        margin-right: 0.5rem;
      }

      & > span {
        font-size: 1rem;
      }
    }
  }
}
