.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.dashboardActions {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  border-bottom: 0.25rem solid var(--c-primary);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  position: sticky;
  top: 0;

  .tab {
    padding: 0.25rem 0.5rem;
    color: var(--c-primary);
    background: var(--c-white);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;

    &:first-child {
      margin-left: 0rem;
    }
  }

  .active {
    color: var(--c-white);
    background: var(--c-primary);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 30%;
      background: transparent;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      box-shadow: 0.1rem 0.1rem 0.05rem var(--c-primary);
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 30%;
      background: transparent;
      right: 0;
      bottom: 0;
      transform: translateX(100%);
      box-shadow: -0.1rem 0.1rem 0.05rem var(--c-primary);
    }
  }
}
