.services {
  width: 100%;
  padding: 1rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.filter {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  & > div {
    width: 30%;
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 0;
  color: var(--c-black);
}

.addBusiness {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  justify-content: center;
  padding: 0.25rem 1rem !important;
  margin-top: 1rem;
  margin-right: 0.5rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;

  & > span {
    font-size: 1rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 1279px) {
  .filter {
    justify-content: flex-start;

    & > div {
      width: 70%;
    }
  }

  .addBusiness {
    width: fit-content;
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    margin-left: auto;

    & > span {
      font-size: 0.8rem;
      padding-right: 0.25rem;
    }
  }

  .title {
    padding: 0.5rem 0;
  }

  .services {
    padding: 1rem 0;
    grid-template-columns: 1fr 1fr;
  }
}
