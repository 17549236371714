.main {
  position: fixed;
  padding: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  z-index: 100;
  top: 0;
}

@media (max-width: 1279px) {
  .main {
    padding: 0.5rem;
  }
}
