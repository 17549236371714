.main {
  width: 100%;
  height: 100%;
  // padding: 2rem 0;
  overflow: hidden;
  position: relative;

  .locationPicker {
    position: absolute;
    background: var(--c-white);
    border: 0;
    margin: 0.5rem;
    padding: 0;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 0.1rem;
    height: 1.9rem;
    width: 1.9rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    overflow: hidden;
    top: 60%;
    right: 0;

    & > span {
      color: var(--c-red);
    }
  }

  .infoBoxFields {
    display: flex;
    flex-direction: column;

    & > span {
      display: flex;
      align-items: center;

      .logo {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid var(--c-border);
        overflow: hidden;
        margin-right: 0.25rem;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &:nth-child(1) {
        font-weight: 600;
        padding: 0.25rem 0;
        text-transform: capitalize;
      }
    }

    & > div {
      text-decoration: underline;
      color: var(--c-primary);
      font-weight: 500;
      margin: 0.25rem 0;
      text-underline-position: under;
      cursor: pointer;

      &:last-child {
        color: var(--c-blue);
      }
    }
  }
}

@media (max-width: 1279px) {
  .main {
    .locationPicker {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 0.6rem;
      top: 45%;
      z-index: 1;
    }
  }
}
