.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.categoryAnalytics {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}

.headerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;

  & > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0rem;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;
}

.title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1rem;
  color: var(--c-black);
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    &:nth-child(1) {
      width: 25%;
      font-size: 1rem;
      font-weight: 700;
      padding: 1rem 0;
      color: var(--c-black);
      display: flex;
      align-items: center;

      .image {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        overflow: hidden;
        border: 0.1rem solid var(--c-primary);
        margin-right: 0.5rem;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &:nth-child(2) {
      width: 75%;
    }
  }
}

.subCategories {
  padding-bottom: 2rem;

  .subCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      &:nth-child(1) {
        width: 25%;
        font-size: 1rem;
        font-weight: 700;
        padding: 1rem 0;
        color: var(--c-black);
        display: flex;
        align-items: center;

        .image {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          overflow: hidden;
          border: 0.1rem solid var(--c-primary);
          margin-right: 0.5rem;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      &:nth-child(2) {
        width: 75%;
      }
    }
  }
}

.analytics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;

  .analyticsField {
    display: flex;
    align-items: center;
    color: var(--c-primary);
    background: var(--c-white);
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;

    &:nth-child(1) {
      color: var(--c-blue);
    }

    &:nth-child(3) {
      color: var(--c-green);
    }

    &:nth-child(4) {
      color: var(--c-link);
    }

    & > span {
      margin-right: 0.5rem;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem !important;
  justify-content: flex-end;
  width: 100%;
  border-top: 0.1rem solid var(--c-primary);

  & > a,
  & > div {
    color: var(--c-white);
    border: 0.1rem solid var(--c-primary);
    background: var(--c-primary);
    // margin-right: 1rem;
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    white-space: nowrap;

    & > span {
      margin-right: 0.25rem;
      font-size: 1rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1279px) {
  .headerBottom {
    width: 100%;
    margin-left: 0;

    & > div {
      margin-right: 0;
      width: 100%;

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}
